import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { Link } from "gatsby"



const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Content>
	    <h1>404: Not Found</h1>
	    <p>Sorry we couldn't find the page you were looking for.</p>
	    <Link to="/">Take me home</Link>
    </Content>
  </Layout>
)

export default NotFoundPage

const Content = styled.div `
	max-width: 800px;
	margin: 0 auto;
	color: #fff;
	font-size: 20px;
	height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

h1 {
	font-size: 40px;
    letter-spacing: 2px;
}	

a {
	 background-color: #3c7db6;
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border: 2px solid #3c7db6;

    &:hover {
    	background-color: transparent;
		border: 2px solid #3c7db6;
    }
}
`